import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  data: any;
  onEditClick: any;
  onDeleteClick: any;
  onDetailClick: any;
}

const ChargeBoxTable = ({ data, onEditClick, onDeleteClick, onDetailClick }: IProps) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ChargeBox ID</TableCell>
            <TableCell>ChargeBox Nick Name</TableCell>
            <TableCell>price per kW</TableCell>
            <TableCell>Base price</TableCell>
            <TableCell>Detail</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d: any) => (
            <TableRow key={d?.id}>
              <TableCell>{d?.chargeBoxId}</TableCell>
              <TableCell>{d?.nickNm}</TableCell>
              <TableCell>{d?.price}</TableCell>
              <TableCell>{d?.basePrice}</TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onDetailClick(d)}>
                  Detail
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onEditClick(d)}>
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onDeleteClick(d)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChargeBoxTable;
