import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 12px 14px;

  .sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    @media (min-width: 375px) {
      font-size: 17px;
      line-height: 22px;
    }
    @media (min-width: 450px) {
      font-size: 19px;
      line-height: 24px;
    }
    @media (min-width: 900px) {
      font-size: 23px;
      line-height: 28px;
    }
    color: #999999;
  }

  .main-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    margin-top: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    @media (min-width: 375px) {
      font-size: 22px;
      line-height: 26px;
    }
    @media (min-width: 450px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (min-width: 900px) {
      font-size: 28px;
      line-height: 30px;
    }
    color: #000000;
  }
`;

export interface StationInfoProps {
  subText?: string;
  mainText?: any;
  imgSrc?: any;
}

// charging station info
const StationInfo = ({ subText, mainText, imgSrc }: StationInfoProps) => {
  return (
    <Container>
      <div className="sub-text">{subText}</div>
      <div className="main-text">
        {imgSrc && <img src={imgSrc} alt="~" width={28} height={28} />}
        {mainText}
      </div>
    </Container>
  );
};

export default StationInfo;
