import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../utils/cookies';

const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    removeCookie('adminToken');
    removeCookie('adminId');
    navigate('/admin/login');
  };

  return logout;
};

export default useLogout;
