import { useEffect, useState } from 'react';
import useModal from '../../../../hooks/useModal';
import { adminApi } from '../../../../apis/admin';
import { generateToken } from '../../../../utils/common';
import UserModifiedPopup from '../../../../components/popup/UserModifiedPopup';
import styled from 'styled-components';
import { Box, Button, Grid, Pagination, TextField } from '@mui/material';
import UserTable from './UserTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .user-edit {
    display: flex;
    justify-content: center;

    .box {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ml {
      margin-left: 5px;
    }
  }
`;
const ManageUser = () => {
  const [page, setPage] = useState(1); // 처음 페이지는 1이다.
  const [search, setSearch] = useState(''); // 검색어
  const [tableData, setTableData] = useState([]); // 테이블 데이터
  const pageSize = 5; // 한 페이지에 보여줄 데이터의 개수
  const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
  const modal = useModal();

  useEffect(() => {
    findUser();
  }, [page, pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    findUser();
  };

  const findUser = () => {
    adminApi
      .findUser(page.toString(), pageSize.toString(), search)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setTableData(res.data.result.userList);
          const total = res.data.result.total;
          const totalPage = Math.ceil(total / pageSize);
          setTotalPage(totalPage);
        }
      })
      .catch((err: any) => {
        window.alert('fail to find user');
      });
  };

  const handleAddClick = () => {
    const data = {
      id: '',
      password: '',
      token: generateToken(20),
    };
    modal.openModal({
      key: 'USER_MODIFY',
      title: 'Please enter user information.',
      component: () => <UserModifiedPopup data={data} type="create" />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('USER_MODIFY');
        findUser();
      },
    });
  };

  const handleUpdateClick = (data: any) => {
    modal.openAlert({
      key: 'USER_MODIFY',
      title: 'Please enter user information.',
      component: () => <UserModifiedPopup data={data} type="update" />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('USER_MODIFY');
        findUser();
      },
    });
  };

  const handleDeleteClick = (data: any) => {
    modal.openConfirm({
      key: 'USER_DELETE',
      title: 'Are you sure you want to delete?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      onRequestConfirm: () => {
        adminApi
          .deleteUser(data.userId)
          .then((res: any) => {
            if (res.data && res.data.resultCode === '0000') {
              findUser();
            }
          })
          .catch((err: any) => {
            window.alert('fail to delete user');
          });
      },
      onRequestCancel: () => {},
      onAfterClose: () => {
        modal.closeModal('USER_DELETE');
      },
    });
  };

  return (
    <Container>
      <Box mt={3} mb={3}>
        <h3>Manage ChargePoint</h3>
      </Box>
      <Grid container className="user-edit" spacing={2}>
        <Grid item xs={12} sm={6} className="box">
          <Button variant="contained" fullWidth onClick={handleAddClick}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Box>
        <Box mt={2} className="row">
          <TextField size="small" label="User ID" value={search} onChange={handleSearchChange} />
          <Button variant="outlined" className="ml" onClick={handleSearchClick}>
            search
          </Button>
        </Box>
        <Box mt={2}>
          <UserTable data={tableData} onEditClick={handleUpdateClick} onDeleteClick={handleDeleteClick} />
        </Box>
        <Box mt={2} className="user-edit">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            boundaryCount={2}
          />
        </Box>
      </Box>
    </Container>
  );
};
export default ManageUser;
