import styled from 'styled-components';
import { Box, Button, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import { useState } from 'react';
import useModal from '../../hooks/useModal';
import { adminApi } from '../../apis/admin';
import { generateToken } from '../../utils/common';

const Container = styled.div``;

interface IProps {
  data: any;
  type: string;
}

const ChargeBoxModifiedPopup = ({ data, type }: IProps) => {
  const [id, setId] = useState(data?.id || '');
  const [chargePointId, setChargePointId] = useState(data?.chargePointId || '');
  const [chargeBoxId, setChargeBoxId] = useState(data?.chargeBoxId || '');
  const [urlCode, setUrlCode] = useState(data?.urlCode || '');
  const [price, setPrice] = useState(data?.price || 0);
  const [basePrice, setBasePrice] = useState(data?.basePrice || 0);
  const [nickNm, setNickNm] = useState(data?.nickNm || '');

  const modal = useModal();
  const handleSaveClick = () => {
    if (chargePointId === '') {
      window.alert('Please check ChargePoint ID');
      return;
    }

    if (chargeBoxId === '') {
      window.alert('Please input ChargeBox ID');
      return;
    }

    const data = {
      chargePointId: chargePointId,
      chargeBoxId: chargeBoxId,
      urlCode: urlCode,
      price: price,
      basePrice: basePrice,
      nickNm: nickNm,
    };
    adminApi
      .createChargeBox(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('CHARGE_BOX_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to create charge box');
      });
  };

  const handleUpdateClick = () => {
    if (chargePointId === '') {
      window.alert('Please input ChargePoint ID');
      return;
    }

    const data = {
      id: id,
      chargePointId: chargePointId,
      chargeBoxId: chargeBoxId,
      urlCode: urlCode,
      price: price,
      basePrice: basePrice,
      nickNm: nickNm,
    };
    adminApi
      .updateChargeBox(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('CHARGE_BOX_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to update charge box');
      });
  };

  return (
    <Container>
      <Box mt={2}>
        <TextField
          size="small"
          label="ChargeBox ID*"
          value={chargeBoxId}
          onChange={e => {
            setChargeBoxId(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="ChargeBox Nick Name"
          value={nickNm}
          onChange={e => {
            setNickNm(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="UrlCode"
          value={urlCode}
          onChange={e => {
            setUrlCode(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="price per kwh"
          type="number"
          value={price}
          onChange={e => {
            setPrice(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Base price"
          type="number"
          value={basePrice}
          onChange={e => {
            setBasePrice(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        {type === 'create' && (
          <Button variant="contained" fullWidth onClick={handleSaveClick}>
            Save
          </Button>
        )}
        {type === 'update' && (
          <Button variant="contained" fullWidth onClick={handleUpdateClick}>
            Update
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ChargeBoxModifiedPopup;
