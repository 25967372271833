import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { convertUtcToLocalFromCreatedDt } from '../../utils/common';

interface IProps {
  data: any;
}
const ChargeBoxDetailPopup = ({ data }: IProps) => {
  const url = `https://www.pt-futura.com/main?code=${data?.urlCode}`;

  return (
    <TableContainer className="formTable-container">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="cell-title">chargePointId</TableCell>
            <TableCell>{data?.chargePointId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">chargeBoxId</TableCell>
            <TableCell>{data?.chargeBoxId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">chargeBox Nick Name</TableCell>
            <TableCell>{data?.nickNm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">urlCode</TableCell>
            <TableCell>
              <div>code : {data?.urlCode}</div>
              <div>url : {url}</div>
              <div>
                <QRCodeCanvas value={url} />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">price for kwh</TableCell>
            <TableCell>{data?.price}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">base price</TableCell>
            <TableCell>{data?.basePrice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">createdDt</TableCell>
            <TableCell>{convertUtcToLocalFromCreatedDt(data?.createdDt).toString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChargeBoxDetailPopup;
