import LayoutContainer from '../../../containers/layout/LayoutContainer';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useState } from 'react';
import ManageChargeBox from './part/ManageChargeBox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DashBoardPage = () => {
  const [searchParams] = useSearchParams();
  const chargePointId = searchParams.get('chargePointId') + '';
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <LayoutContainer>
      <Container>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Manage ChargeBox" value="1" />
          </TabList>
          <TabPanel value="1">
            <ManageChargeBox chargePointId={chargePointId} />
          </TabPanel>
        </TabContext>
      </Container>
    </LayoutContainer>
  );
};

export default DashBoardPage;
