import TopBar from '../components/topBar/TopBar';

import React from 'react';
import OccupiedInfo from '../components/occupiedInfo/OccupiedInfo';

const NotFoundPage = () => {
  return (
    <div className="container">
      <div className="inner">
        <TopBar text="Invalid command" />

        <div className="status-wrap">
          <OccupiedInfo
            occupied={false}
            titleText="Invalid command"
            subText="Sorry for your inconvenience"
            subTextWeek="There is the wrong page url"
            descText="Please contact a customer center"
          />
          <div className="action-wrap">
            <a className="btn-wrap" href="https://wa.me/628818878878">
              Customer center
              <div className="img-wrap">
                <img src="/assets/img/phone.png" alt="~" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
