import styled from 'styled-components';
import { Box, Button, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import { useState } from 'react';
import useModal from '../../hooks/useModal';
import { adminApi } from '../../apis/admin';

const Container = styled.div``;

interface IProps {
  data: any;
  type: string;
}

const ChargePointModifiedPopup = ({ data, type }: IProps) => {
  const [id, setId] = useState(data?.id || '');
  const [chargePointId, setChargePointId] = useState(data?.chargePointId || '');
  const [nickNm, setNickNm] = useState(data?.nickNm || '');
  const [password, setPassword] = useState(data?.password || '');
  const [start, setStart] = useState(data?.start || '0000');
  const [end, setEnd] = useState(data?.end || '2400');
  const [linkUrl, setLinkUrl] = useState(data?.linkUrl || '');
  const [btnNm, setBtnNm] = useState(data?.btnNm || '');
  // autoUpdate 가 0 이거나 null 인경우 false, 1인경우 true
  const [autoUpdate, setAutoUpdate] = useState(data?.autoUpdate === '1' ? true : false);

  const modal = useModal();
  const handleSaveClick = () => {
    if (chargePointId === '') {
      window.alert('Please input ChargePoint ID');
      return;
    }

    const data = {
      chargePointId: chargePointId,
      password: password,
      autoUpdate: autoUpdate ? '1' : '0',
      start: start,
      end: end,
      linkUrl: linkUrl,
      btnNm: btnNm,
      nickNm: nickNm,
    };
    adminApi
      .createChargePoint(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('CHARGE_POINT_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to create charge point');
      });
  };

  const handleUpdateClick = () => {
    if (chargePointId === '') {
      window.alert('Please input ChargePoint ID');
      return;
    }

    const data = {
      id: id,
      chargePointId: chargePointId,
      password: password,
      autoUpdate: autoUpdate ? '1' : '0',
      start: start,
      end: end,
      linkUrl: linkUrl,
      btnNm: btnNm,
      nickNm: nickNm,
    };
    adminApi
      .updateChargePoint(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('CHARGE_POINT_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to update charge point');
      });
  };

  const handleChangeSwitch = (e: any) => {
    setAutoUpdate(e.target.checked);
  };

  return (
    <Container>
      <Box mt={2}>
        <TextField
          size="small"
          label="ChargePoint ID*"
          value={chargePointId}
          onChange={e => {
            setChargePointId(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="ChargePoint Nick Name"
          value={nickNm}
          onChange={e => {
            setNickNm(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={autoUpdate} onChange={handleChangeSwitch} />}
            label="Auto Update Password"
          />
        </FormGroup>
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Opetation StartTime"
          value={start}
          onChange={e => {
            setStart(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Opetation EndTime"
          value={end}
          onChange={e => {
            setEnd(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Link URL"
          value={linkUrl}
          onChange={e => {
            setLinkUrl(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Link Button Name"
          value={btnNm}
          onChange={e => {
            setBtnNm(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        {type === 'create' && (
          <Button variant="contained" fullWidth onClick={handleSaveClick}>
            Save
          </Button>
        )}
        {type === 'update' && (
          <Button variant="contained" fullWidth onClick={handleUpdateClick}>
            Update
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default ChargePointModifiedPopup;
