import React, { useEffect } from 'react';

import classnames from 'classnames';

import type * as Modal from '../../../types/modal';

const Alert = ({
  onAfterOpen,
  onAfterClose,
  isOpen = false,
  title,
  message,
  confirmButtonText,
  component,
  onRequestClose,
}: Modal.IModalProps) => {
  useEffect(() => {
    onAfterOpen && onAfterOpen();
    return () => onAfterClose && onAfterClose();
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleCloseClick = () => {
    onRequestClose && onRequestClose();
  };

  return (
    <div className={classnames('modal-wrap', isOpen ? 'fade-in' : 'fade-out')}>
      <div className="modal">
        <div className="title">{title}</div>
        <div className="content">
          {message && message}
          {component && component()}
        </div>
        <div className="button-wrap">
          <div className="button" onClick={handleCloseClick}>
            {confirmButtonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
