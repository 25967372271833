import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainPage from './pages/MainPage';
import StatusPage from './pages/StatusPage';
import Provider from './providers';
import LoginPage from './pages/admin/Login/LoginPage';
import HomePage from './pages/admin/home/HomePage';
import DashBoardPage from './pages/admin/DashBoard/DashBoardPage';
import ChargingPage from './pages/ChargingPage';
import FinisedPage from './pages/FinisedPage';
import NotFoundPage from './pages/NotFoundPage';
import MidtransPage from "./pages/MidtransPage";

function App() {
  return (
    <Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/main" element={<MainPage />} />
          <Route path="/status" element={<StatusPage />} />
          <Route path="/charging" element={<ChargingPage />} />
          <Route path="/finished" element={<FinisedPage />} />
          <Route path="/admin/login" element={<LoginPage />} />
          <Route path="/admin" element={<HomePage />} />
          <Route path="/admin/dashboard" element={<DashBoardPage />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/" element={<MidtransPage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
