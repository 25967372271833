interface TopBarProps {
  text?: string;
}

// top bar text
const TopBar = ({ text }: TopBarProps) => {
  return (
    <div className="top-bar">
      <div className="sub-text">{text}</div>
    </div>
  );
};

export default TopBar;
