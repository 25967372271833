import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  padding: 16px 16px;
  position: relative;
  width: 100%;
  text-align: center;
  background: #f6f3f2;

  img {
    text-align: center;
    width: 100%;
    max-width: 680px;
    height: auto;
  }

  .sub-container {
    position: absolute;
    background: rgba(2, 13, 24, 0.8);
    backdrop-filter: blur(7px);
    width: calc(100% - 64px);

    left: 32px;
    top: 32px;
    padding: 18px 12px;

    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    gap: 10px;
  }

  .sub-text {
    text-align: left;

    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    @media (min-width: 375px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (min-width: 450px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (min-width: 900px) {
      font-size: 32px;
      line-height: 35px;
    }
    color: #ffffff;

    .strong {
      font-weight: 700;
    }

    .week {
      margin-top: 10px;
      font-size: 17px;
      line-height: 22px;
      @media (min-width: 375px) {
        font-size: 19px;
        line-height: 24px;
      }
      @media (min-width: 450px) {
        font-size: 22px;
        line-height: 27px;
      }
      @media (min-width: 900px) {
        font-size: 27px;
        line-height: 32px;
      }
    }
  }

  .line {
    border: 1px solid #cccccc;
  }

  .desc-wrap {
    display: flex;
    margin-top: 6px;
  }

  .desc-text1 {
    text-align: left;
    flex: 1;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 29px;
    }

    color: #cccccc;
  }

  .desc-text2 {
    text-align: left;
    flex: 1;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 29px;
    }
    color: #ffffff;
  }

  .desc-text {
    white-space: pre-wrap;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    @media (min-width: 375px) {
      font-size: 15px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 20px;
      line-height: 29px;
    }
    @media (min-width: 900px) {
      font-size: 23px;
      line-height: 33px;
    }
    color: #cccccc;
  }
`;

const StatusText = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  @media (min-width: 375px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (min-width: 450px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media (min-width: 900px) {
    font-size: 25px;
    line-height: 30px;
  }
  color: ${props => (props.occupied === true ? '#00aad2' : '#E63312')};

  .img-wrap {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    @media (min-width: 375px) {
      width: 23px;
      height: 23px;
    }
    @media (min-width: 450px) {
      width: 25px;
      height: 25px;
    }
    @media (min-width: 900px) {
      width: 30px;
      height: 30px;
    }
  }
`;

interface OccupiedInfoProps {
  occupied: boolean;
  titleText?: string;
  subText?: string;
  subTextStrong?: string;
  subTextWeek?: string;
  descText?: string;
  transactionText?: any[];
}

const OccupiedInfo = ({
  occupied,
  titleText,
  subText,
  subTextStrong,
  subTextWeek,
  descText,
  transactionText,
}: OccupiedInfoProps) => {
  return (
    <Container>
      <img src="/assets/img/occupiedBg.png" alt="~" />
      <div className="sub-container">
        <StatusText occupied={occupied}>
          <div className="img-wrap">
            <img src={occupied ? '/assets/img/status1.png' : '/assets/img/status2.png'} alt="~" />
          </div>
          {titleText}
        </StatusText>
        <div className="sub-text">
          {subText}
          {subTextStrong && <div className="strong">{subTextStrong}</div>}
          {subTextWeek && <div className="week">{subTextWeek}</div>}
        </div>
        <div className="line"></div>
        <div className="desc-text">{descText}</div>
        <div>
          {transactionText?.map((text, index) => (
            <div className="desc-wrap" key={index}>
              <div className="desc-text1">{text.title}</div>
              <div className="desc-text2">{text.data}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default OccupiedInfo;
