import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  data: any;
  onEditClick: any;
  onDeleteClick: any;
}

const UserTable = ({ data, onEditClick, onDeleteClick }: IProps) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>PassWord</TableCell>
            <TableCell>token</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d: any) => (
            <TableRow key={d?.id}>
              <TableCell>{d?.userId}</TableCell>
              <TableCell>{d?.password}</TableCell>
              <TableCell>{d?.token}</TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onEditClick(d)}>
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onDeleteClick(d)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
