import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, TextField } from '@mui/material';
import { numberWithZum } from '../../utils/common';

const Container = styled.div`
  .font-bold {
    font-weight: bold;
    font-size: 8px;
  }

  .full-width-right {
    text-align: right;
    width: 100%;
  }

  .left {
    text-align: left;
  }
`;

interface PaymentPopupProps {
  onPasswordConfirmClick: any;
  price: number;
  basePrice: number;
  usePassword: boolean;
  onPaymentClick: any;
}

const PaymentPopup = ({ onPasswordConfirmClick, price, basePrice, usePassword, onPaymentClick }: PaymentPopupProps) => {
  const [password, setPassword] = useState('');
  const [requestKwh, setRequestKwh] = useState(0);
  const [expectedPrice, setExpectedPrice] = useState(0);
  const handleChangeRequestKwh = (e: any) => {
    const value: number = Number(e.target.value);

    setRequestKwh(value);
  };

  useEffect(() => {
    if (!requestKwh || requestKwh === 0) {
      setExpectedPrice(0);
    } else {
      setExpectedPrice(requestKwh * price + basePrice);
    }
  }, [requestKwh]);

  return (
    <Container>
      {usePassword && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            Free policy applies when entering password.
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item alignItems="center" xs={6}>
                <TextField label="password" size="small" value={password} onChange={e => setPassword(e.target.value)} />
              </Grid>
              <Grid item xs={6}>
                <div className="default-btn-wrap" onClick={e => onPasswordConfirmClick(password)}>
                  Confirm
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      <Box mt={2}>
        <Box>
          <h4 className="left">
            <div>Expected price</div>
            <div className="font-bold">
              (Per Kwh : Rp{numberWithZum(price)} / Base price : Rp{numberWithZum(basePrice)})
            </div>
          </h4>
          <div className="full-width-right">
            <h4>Rp{numberWithZum(expectedPrice)}</h4>
          </div>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item alignItems="center" xs={6}>
              <TextField
                label="kwh"
                size="small"
                type="number"
                value={requestKwh.toString()}
                onChange={handleChangeRequestKwh}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="default-btn-wrap" onClick={e => onPaymentClick(expectedPrice, requestKwh)}>
                Confirm
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default PaymentPopup;
