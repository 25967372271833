import styled from 'styled-components';
import { useState } from 'react';
import useModal from '../../hooks/useModal';
import { adminApi } from '../../apis/admin';
import { Box, Button, TextField } from '@mui/material';

const Container = styled.div``;

interface IProps {
  data: any;
  type: string;
}

const UserModifiedPopup = ({ data, type }: IProps) => {
  const [id, setId] = useState(data?.id || '');
  const [userId, setUserId] = useState(data?.userId || '');
  const [password, setPassword] = useState(data?.password || '');
  const [chargePointId, setChargePointId] = useState(data?.chargePointId || '');
  const [token, setToken] = useState(data?.token || '');

  const modal = useModal();

  const handleSaveClick = () => {
    if (userId === '') {
      window.alert('Please input User ID');
      return;
    }

    const data = {
      userId: userId,
      password: password,
      chargePointId: chargePointId,
      token: token,
    };
    adminApi
      .createUser(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('USER_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to create user');
      });
  };

  const handleUpdateClick = () => {
    if (userId === '') {
      window.alert('Please input User ID');
      return;
    }

    const data = {
      id: id,
      userId: userId,
      password: password,
      chargePointId: chargePointId,
      token: token,
    };
    adminApi
      .updateUser(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('USER_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to update user');
      });
  };

  return (
    <Container>
      <Box mt={2}>
        <TextField
          size="small"
          label="User ID*"
          value={userId}
          onChange={e => {
            setUserId(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Password*"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="ChargePoint Id List"
          multiline
          rows={3}
          value={chargePointId}
          onChange={e => {
            setChargePointId(e.target.value);
          }}
          helperText="When entering multiple chargePoint, separate them with (,)"
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Token"
          value={token}
          onChange={e => {
            setToken(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        {type === 'create' && (
          <Button variant="contained" fullWidth onClick={handleSaveClick}>
            Save
          </Button>
        )}
        {type === 'update' && (
          <Button variant="contained" fullWidth onClick={handleUpdateClick}>
            Update
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default UserModifiedPopup;
