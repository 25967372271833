import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useModal from '../../../../hooks/useModal';
import AutelRowDetailPopup from '../../../../components/popup/AutelRowDetailPopup';
import { convertUtcToLocalFromCreatedDt } from '../../../../utils/common';

interface IProps {
  data: any;
}
const AutelRowTable = ({ data }: IProps) => {
  const modal = useModal();
  const handleClickDetail = (d: any) => {
    modal.openAlert({
      key: 'DETAIL_TRANSACTION',
      title: 'Detail transaction information.',
      component: () => <AutelRowDetailPopup data={d} />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('DETAIL_TRANSACTION');
      },
    });
  };
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>sessionId</TableCell>
            <TableCell>sn(device)</TableCell>
            <TableCell>connector</TableCell>
            <TableCell>chargeDuration</TableCell>
            <TableCell>energy</TableCell>
            <TableCell>createdDt</TableCell>
            <TableCell>Detail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d: any) => (
            <TableRow key={d?.id}>
              <TableCell>{d?.sessionId}</TableCell>
              <TableCell>{d?.sn}</TableCell>
              <TableCell>{d?.connector}</TableCell>
              <TableCell>{d?.chargeDuration}</TableCell>
              <TableCell>{d?.energy}</TableCell>
              <TableCell>{convertUtcToLocalFromCreatedDt(d?.createdDt).toString()}</TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => handleClickDetail(d)}>
                  Detail
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutelRowTable;
