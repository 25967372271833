import styled from 'styled-components';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button, Pagination, TextField } from '@mui/material';
import { getYYYYMMDD } from '../../../../utils/common';
import { adminApi } from '../../../../apis/admin';
import TransactionTable from './TransactionTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .transaction {
    display: flex;
    justify-content: center;

    .box {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ml {
      margin-left: 5px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: column;
  }
`;
const TransactionHistory = () => {
  const [page, setPage] = useState(1); // 처음 페이지는 1이다.
  const [search, setSearch] = useState(''); // 검색어
  const [tableData, setTableData] = useState([]); // 테이블 데이터
  const pageSize = 5; // 한 페이지에 보여줄 데이터의 개수
  const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    findTransaction();
  }, [page, pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSearchClick = () => {
    findTransaction();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const findTransaction = () => {
    adminApi
      .findTransaction(page.toString(), pageSize.toString(), search, getYYYYMMDD(startDate), getYYYYMMDD(endDate))
      .then(res => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setTableData(res.data.result.transactionList);
          const total = res.data.result.total;
          const totalPage = Math.ceil(total / pageSize);
          setTotalPage(totalPage);
        }
      })
      .catch(err => {
        window.alert('fail to find transaction');
      });
  };

  return (
    <Container>
      <Box mt={3} mb={3}>
        <h3>Transaction Monitoring</h3>
      </Box>
      <Box>
        <Box mt={2} className="row">
          <Box className="flex-row">
            <h6>StartDate</h6>
            <DatePicker selected={startDate} onChange={date => handleStartDateChange(date)} />
          </Box>
          <Box className="flex-row">
            <h6>EndDate</h6>
            <DatePicker selected={endDate} onChange={date => handleEndDateChange(date)} />
          </Box>
          <TextField size="small" label="Transaction ID" value={search} onChange={handleSearchChange} />
          <Button variant="outlined" className="ml" onClick={handleSearchClick}>
            search
          </Button>
        </Box>
        <Box mt={2}>
          <TransactionTable data={tableData} />
        </Box>
        <Box mt={2} className="transaction">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            boundaryCount={2}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default TransactionHistory;
