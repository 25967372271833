import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { convertUtcToLocalFromCreatedDt } from '../../utils/common';

interface IProps {
  data: any;
}
const AutelRowDetailPopup = ({ data }: IProps) => {
  return (
    <TableContainer className="formTable-container">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="cell-title">sessionId</TableCell>
            <TableCell>{data?.sessionId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">sn</TableCell>
            <TableCell>{data?.sn}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">connector</TableCell>
            <TableCell>{data?.connector}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">chargeDuration</TableCell>
            <TableCell>{data?.chargeDuration}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">meterValueStart</TableCell>
            <TableCell>{data?.meterValueStart}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">meterValueEnd</TableCell>
            <TableCell>{data?.meterValueEnd}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">energy</TableCell>
            <TableCell>{data?.energy}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">startTime</TableCell>
            <TableCell>{data?.startTime}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">endTime</TableCell>
            <TableCell>{data?.endTime}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">reasonForStop</TableCell>
            <TableCell>{data?.reasonForStop}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">createdDt</TableCell>
            <TableCell>{convertUtcToLocalFromCreatedDt(data?.createdDt).toString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutelRowDetailPopup;
