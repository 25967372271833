import axios from 'axios';
import { getCookie } from './cookies';

// axios config
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

/**
 * basic axios
 */
const plugitAxios = axios.create({
  baseURL: process.env.REACT_APP_PLUGIT_HOST,
  timeout: 10 * 1000,
  responseType: 'json',
});

plugitAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

const adminAxios = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10 * 1000,
  responseType: 'json',
});

adminAxios.interceptors.request.use(
  config => {
    const accessToken = getCookie('adminToken');
    if (accessToken) {
      config.headers['Authorization'] = `${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

adminAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export { plugitAxios, adminAxios };
