import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { convertUtcToLocal, convertUtcToLocalFromCreatedDt, getMMDDHHMM } from '../../utils/common';

interface IProps {
  data: any;
}
const TransactionDetailPopup = ({ data }: IProps) => {
  return (
    <TableContainer className="formTable-container">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="cell-title">transactionId</TableCell>
            <TableCell>{data?.transactionId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">chargePointId</TableCell>
            <TableCell>{data?.chargePointId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">chargeBoxId</TableCell>
            <TableCell>{data?.chargeBoxId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">eventType</TableCell>
            <TableCell>{data?.eventType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">idTag</TableCell>
            <TableCell>{data?.idTag}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">meterStart</TableCell>
            <TableCell>{data?.meterStart}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">meterStop</TableCell>
            <TableCell>{data?.meterStop}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">timeStart</TableCell>
            <TableCell>{getMMDDHHMM(data?.timeStart)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">timeStop</TableCell>
            <TableCell>{getMMDDHHMM(data?.timeStop)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">state</TableCell>
            <TableCell>{data?.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">energy</TableCell>
            <TableCell>{data?.energy}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">duration</TableCell>
            <TableCell>{data?.duration}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">value</TableCell>
            <TableCell>{data?.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">unit</TableCell>
            <TableCell>{data?.unit}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">measurand</TableCell>
            <TableCell>{data?.measurand}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="cell-title">createdDt</TableCell>
            <TableCell>{convertUtcToLocalFromCreatedDt(data?.createdDt).toString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionDetailPopup;
