import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
interface IProps {
  data: any;
  onSelectClick: any;
}

const SelectChargePointTable = ({ data, onSelectClick }: IProps) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ChargePoint ID</TableCell>
            <TableCell>ChargePoint Nick Name</TableCell>
            <TableCell>Move</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d: any) => (
            <TableRow key={d?.id}>
              <TableCell>{d?.chargePointId}</TableCell>
              <TableCell>{d?.nickNm}</TableCell>
              <TableCell>
                <Button variant="contained" size="small" onClick={e => onSelectClick(d?.chargePointId)}>
                  <LoginIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectChargePointTable;
