import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { MODAL_TYPE } from '../constants/modal';
import { modalState } from '../stores/modal';

import type * as Modal from '../types/modal';

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalState);

  const createUid = useCallback(() => {
    if (typeof window !== undefined && window.crypto) {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      return array[0].toString(36);
    } else {
      return Math.random().toString(36).substring(2, 9);
    }
  }, []);

  const openHashMap = new Map(modals.map(modal => [modal.key, modal]));

  const openModal = (props: Modal.IModalProps) => {
    // Modal 고유의 Key 생성후 isOpen을 true로 설정
    props.key = props.key || createUid();
    props.isOpen = true;
    openHashMap.set(props.key, props);
    props.onRequestClose = async () => {
      if (!props.key) {
        return;
      }

      const closeHashMap = new Map(openHashMap);
      closeHashMap.delete(props.key);
      setModals(Array.from(closeHashMap.values()));
    };

    setModals(Array.from(openHashMap.values()));
  };

  const closeModal = (key?: string) => {
    if (!key) {
      return;
    }

    const props = openHashMap.get(key);

    if (!props) {
      return;
    }

    openHashMap.delete(key);
    setModals(Array.from(openHashMap.values()));
  };

  return {
    openModal,
    openAlert: (props: Modal.IModalProps) => openModal({ ...props, type: MODAL_TYPE.ALERT }),
    openConfirm: (props: Modal.IModalProps) => openModal({ ...props, type: MODAL_TYPE.CONFIRM }),
    closeModal,
  };
};

export default useModal;
