import { useLocation, useSearchParams } from 'react-router-dom';
import TopBar from '../components/topBar/TopBar';
import { useEffect, useState, useRef } from 'react';
import { plugitApi } from '../apis/plugit';
import { IChargeBasicInfo, IChargeBox, IChargePoint, IStartTransaction, ITransaction } from '../types/common';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StationInfo from '../components/stationInfo/StationInfo';
import DescCharger from '../components/descCharger/DescCharger';
import { adminApi } from '../apis/admin';
import { currentTime, generateIdTag } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import PaymentPopup from '../components/popup/PaymentPopup';
import useModal from '../hooks/useModal';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const SkeletonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 0px;
  @media (min-width: 375px) {
    padding: 13px 13px 0px;
  }
  @media (min-width: 450px) {
    padding: 15px 15px 0px;
  }
  @media (min-width: 900px) {
    padding: 20px 20px 0px;
  }

  width: 100vw;
`;

const MainPage = () => {
  const [searchParams] = useSearchParams();
  const urlCode = searchParams.get('code') || '';
  const orderId = searchParams.get('order_id') || '';
  const paymentResult = searchParams.get('result') || '';
  const amountResult = searchParams.get('amount') || '';

  const Ref = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isRefund, setIsRefund] = useState(false); // 환불 여부
  const [chargeBox, setChargeBox] = useState<IChargeBox>();
  const [chargePoint, setChargePoint] = useState<IChargePoint>();
  const [isActivated, setIsActivated] = useState(false);
  const [timer, setTimer] = useState(0);
  const [idTag, setIdTag] = useState(generateIdTag(6)); // idTag
  const [chargeBasicInfo, setChargeBasicInfo] = useState<IChargeBasicInfo>({});

  const navigate = useNavigate();

  const modal = useModal();

  useEffect(() => {
    if (paymentResult === 'success' && !isLoading && orderId !== '') {
      handleActivate();
    } else if (paymentResult === 'failure' && !isLoading) {
      PaymentError();
    }
  }, [paymentResult, isLoading, orderId]);

  const handleReloadClick = () => {
    if (!isActivated) {
      window.location.reload();
    }
  };

  const PaymentError = () => {
    modal.openAlert({
      key: 'ERROR_PAYMENT',
      title: 'Payment failed',
      message:
        'Please try again few seconds. There has been a payment failed. If the problem continues, please contact the customer center (08001878878)',
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('ERROR_PAYMENT');
      },
    });
  };

  const refundPayment = () => {
    if (paymentResult === 'success' && isRefund === false) {
      adminApi
        .getRefundInfo(orderId, amountResult)
        .then((res: any) => {
          if (res.data && res.data.resultCode === '0000' && res.data.result && res.data.result.statusCode === '200') {
            setIsRefund(true);
          } else {
            PaymentError();
          }
        })
        .catch((err: any) => {
          PaymentError();
        });
    }
  };
  const handlePaymentClick = (expectedPrice: number, requestKwh: number) => {
    if (requestKwh < 1) {
      window.alert('Please enter 1kwh or more.');
      return;
    }

    adminApi
      .getPaymentInfo(expectedPrice.toString(), urlCode)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          const result = res.data.result;
          if (result.statusCode === '201') {
            const actions = result.actions;
            // actions 배열중에 name 이 deeplink-redirect 인 url 찾는다
            const deeplinkRedirect = actions.find((action: any) => action.name === 'deeplink-redirect');
            if (deeplinkRedirect) {
              window.location.href = deeplinkRedirect.url;
            }
          }
        } else {
          commonError();
        }
      })
      .catch((err: any) => {
        commonError();
      });
  };

  const handleModalClick = () => {
    // 비용을 받는 경우
    if (chargeBasicInfo?.price && chargeBasicInfo?.price > 0) {
      modal.openAlert({
        key: 'Activate',
        title: 'Please enter the charging amount.',
        component: () => (
          <PaymentPopup
            onPasswordConfirmClick={handlePasswordConfirm}
            price={chargeBasicInfo?.price || 0}
            basePrice={chargeBasicInfo?.basePrice || 0}
            usePassword={chargeBasicInfo?.usePassword || false}
            onPaymentClick={handlePaymentClick}
          />
        ),
        confirmButtonText: 'Close',
        onAfterClose: () => {
          modal.closeModal('Activate');
        },
      });
    } else {
      // 비용을 받지 않는 경우
      handleActivate();
    }
  };

  const commonError = () => {
    modal.openAlert({
      key: 'Error',
      title: 'Connection error',
      message:
        'Please try again few seconds. There has been a network problem. If the problem continues, please contact the customer center (08001878878)',
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('Error');
      },
    });
  };

  const passwordError = () => {
    modal.openAlert({
      key: 'PW_ERROR',
      title: 'Incorrect password entered',
      message: 'Please try again after check password.',
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('PW_ERROR');
      },
    });
  };

  useEffect(() => {
    if (urlCode === '') {
      navigate('/status?status=offline');
      return;
    }

    // get chargeBoxInformation
    adminApi
      .getChargeBasicInfo(urlCode)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setChargeBasicInfo(res.data.result);

          // 영업 시간 아닌 경우 offline으로 이동
          const now = currentTime();
          const start = res.data.result.start; // 시작시간 (ex: 0900)
          const end = res.data.result.end; // 종료시간 (ex: 1800)

          // 영업 시간이 아닌 경우 offline으로 이동
          if (start && end && !(now >= start && now <= end)) {
            navigate('/status?status=offline&start=' + start + '&end=' + end);
          }
        }
      })
      .catch((err: any) => {
        navigate('/status?status=offline');
        return;
      });
  }, []);

  useEffect(() => {
    if (chargeBasicInfo.chargePointId && chargeBasicInfo.chargeBoxId) {
      // Save data after first checking charging station and charger information
      plugitApi
        .getChargePoints(chargeBasicInfo.chargePointId)
        .then((res: any) => {
          const data = res.data;
          setChargePoint(data);
          plugitApi
            .getChargeBoxes(chargeBasicInfo.chargeBoxId)
            .then((res: any) => {
              const data = res.data;

              if (data.status === 'Unavailable' || data.status === 'Faulted') {
                navigate('/status?status=offline');
              } else if (data.status === 'Available' || data.status === 'Preparing') {
                // case of Available, Preparing
                setChargeBox(data);
                setIsLoading(false);
              } else {
                navigate('/status?status=occupied');
              }
            })
            .catch((err: any) => {
              navigate('/status?status=offline');
            });
        })
        .catch((err: any) => {
          navigate('/status?status=offline');
        });
    }
  }, [chargeBasicInfo]);

  const startTimer = () => {
    setTimer(160);
  };

  const handleActivate = () => {
    if (isActivated) {
      return;
    }

    setIsActivated(true);

    const data: IStartTransaction = {
      idTag: idTag,
      chargeBoxId: chargeBasicInfo.chargeBoxId,
    };

    adminApi.checkRfid().then((res: any) => {
      if(res.data && res.data.resultCode === '0000' && res.data.result) {
        const result = res.data.result;
        if(result === 'success') {
          plugitApi
              .remoteStartTransaction(data)
              .then((res: any) => {
                if (res.data && res.data.status === 'Accepted') {
                  startTimer();
                  createAfterPayment();
                } else {
                  commonError();
                  setIsActivated(false);
                  refundPayment();
                }
              })
              .catch((err: any) => {
                commonError();
                setIsActivated(false);
                refundPayment();
              });
        }
      }
    });




  };

  const createAfterPayment = () => {
    if (paymentResult === 'success') {
      let requestKw: number = 0;
      if (chargeBasicInfo?.price) {
        requestKw = (Number(amountResult) - (chargeBasicInfo?.basePrice || 0)) / (chargeBasicInfo?.price | 0);
      }

      const data = {
        chargePointId: chargeBasicInfo.chargePointId,
        chargeBoxId: chargeBasicInfo.chargeBoxId,
        eventType: 'PaymentCreate',
        requestKw: requestKw,
        idTag: idTag,
        orderId: orderId,
        amount: amountResult,
        price: chargeBasicInfo.price,
      };
      adminApi
        .createAfterPayment(data)
        .then((res: any) => {})
        .catch((err: any) => {});
    }
  };

  useEffect(() => {
    if (Ref.current) {
      clearInterval(Ref.current);
    }

    const id = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsActivated(false);
        clearInterval(Ref.current);
      }
    }, 1000);
    Ref.current = id;

    return () => {
      if (Ref.current !== null) {
        clearInterval(Ref.current);
      }
    };
  }, [timer]);

  // 3초 간격으로 충전기 상태 체크
  useEffect(() => {
    if (timer === 0) {
      return;
    }

    if (timer % 3 === 0) {
      adminApi
        .getTranscactionInfo(idTag, chargeBasicInfo.chargePointId || '', chargeBasicInfo.chargeBoxId || '')
        .then((res: any) => {
          if (res.data && res.data.resultCode === '0000' && res.data.result) {
            const result: ITransaction = res.data.result;

            if (result.eventType === 'TransactionStart') {
              navigate('/charging?code=' + urlCode + '&idTag=' + idTag);
            }
          }
        })
        .catch((err: any) => {});
    }
  }, [timer]);

  const handlePasswordConfirm = (password: string) => {
    adminApi
      .checkChargePointPassword(chargeBasicInfo.chargePointId || '', password)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          modal.closeModal('Activate');
          handleActivate();
        } else {
          passwordError();
        }
      })
      .catch((err: any) => {
        if (err.response && err.response.status === 401) {
          passwordError();
        } else {
          commonError();
        }
      });
  };

  return (
    <div className="container">
      <div className="inner">
        <TopBar text={isLoading ? '' : 'Active charger'} />
        {isLoading ? (
          <Skeleton height={70} wrapper={SkeletonWrap} count={5} />
        ) : (
          <>
            <div className="charger-wrap">
              <div className="station">
                <div className="sub-text">
                  <img src="/assets/img/loc.png" alt="~" width={20} height={20} />
                  <div>Station</div>
                </div>
                <div className="main-text">{chargePoint?.name}</div>
              </div>
              <div className="station-info">
                <StationInfo subText="Plug ID" mainText={chargeBox?.uniqueId} />
                <StationInfo
                  subText="Plug type"
                  mainText={chargeBox?.socketType}
                  imgSrc={
                    chargeBox?.socketType?.charAt(0) === 'T' ? '/assets/img/plugType1.png' : '/assets/img/plugType2.png'
                  }
                />
                <StationInfo subText="Speed" mainText={(chargeBox?.power || 0) / 1000 + 'kw'} />
              </div>
            </div>
            <DescCharger subText="Please plug the cable first and activate the charger" />
            {chargeBox?.status === 'Preparing' && (
              <div className="charger-action-wrap">
                <button
                  className={classnames('button-wrap', { disabled: isActivated })}
                  disabled={isActivated}
                  onClick={handleModalClick}
                >
                  {isActivated && timer > 0 ? <>Plug the cable in ({timer}s) </> : <>Start charging</>}
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="footer">
        <div className="one">
          <button className="button-wrap" onClick={handleReloadClick}>
            <RestartAltIcon fontSize="small" />
            <span>Refresh Page</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
