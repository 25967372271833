import LayoutContainer from '../../../containers/layout/LayoutContainer';
import styled from 'styled-components';
import { Tab } from '@mui/material';
import ManageChargePoint from './part/ManageChargePoint';
import SelectChargePoint from './part/SelectChargePoint';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { getCookie } from '../../../utils/cookies';
import ManageUser from './part/ManageUser';
import TransactionHistory from './part/TransactionHistory';
import ManageAutel from './part/ManageAutel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HomePage = () => {
  const isAdmin = getCookie('adminId') === 'admin' ? true : false;
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <LayoutContainer>
      <Container>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Select ChargePoint" value="1" />
            {isAdmin && <Tab label="Manage ChargePoint" value="2" />}
            {isAdmin && <Tab label="Manage User" value="3" />}
            {isAdmin && <Tab label="Transaction Monitoring" value="4" />}
            {isAdmin && <Tab label="Autel Monitoring" value="5" />}
          </TabList>
          <TabPanel value="1">
            <SelectChargePoint />
          </TabPanel>
          <TabPanel value="2">
            <ManageChargePoint />
          </TabPanel>
          <TabPanel value="3">
            <ManageUser />
          </TabPanel>
          <TabPanel value="4">
            <TransactionHistory />
          </TabPanel>
          <TabPanel value="5">
            <ManageAutel />
          </TabPanel>
        </TabContext>
      </Container>
    </LayoutContainer>
  );
};

export default HomePage;
