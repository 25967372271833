import { AppBar, Box, Button, Container, CssBaseline, Link, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import useLogout from '../../hooks/useLogout';
import useModal from '../../hooks/useModal';
import { getCookie } from '../../utils/cookies';

interface ILayoutProps {
  children: React.ReactNode;
}

const LayoutContainer = ({ children }: ILayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const modal = useModal();
  const [userId, setUserId] = useState(getCookie('adminId'));
  const [adminToken, setAdminToken] = useState(getCookie('adminToken'));

  useEffect(() => {
    if (location.pathname !== '/admin/login') {
      if (adminToken === undefined) {
        navigate('/admin/login');
      }
    }
  }, [adminToken, location]);

  const handleMove = (path: string) => {
    navigate(path);
  };

  const handleLogoutOpen = () => {
    modal.openConfirm({
      key: 'LOGOUT',
      title: 'Logout',
      message: 'Would you like to log out?',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      onRequestConfirm: () => {
        logout();
      },
      onRequestCancel: () => {},
      onAfterClose: () => {
        modal.closeModal('LOGOUT');
      },
    });
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Button variant="outlined" sx={{ flexGrow: 1, textAlign: 'left' }} onClick={e => handleMove('/admin')}>
            <Typography variant="h6" color="white" component="div" sx={{ flexGrow: 1 }}>
              INES
            </Typography>
          </Button>
          {userId && (
            <Button variant="outlined" onClick={handleLogoutOpen}>
              <Typography variant="h6" color="white" component="div">
                {userId}
              </Typography>
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <Box>{children}</Box>
      </Container>
    </>
  );
};

export default LayoutContainer;
