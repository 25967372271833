import {useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import TopBar from '../components/topBar/TopBar';
import StationInfo from '../components/stationInfo/StationInfo';
import {IChargeBasicInfo, IChargeBox, IChargePoint, ITransaction} from '../types/common';
import {adminApi} from '../apis/admin';
import {plugitApi} from '../apis/plugit';
import Skeleton from 'react-loading-skeleton';
import {SkeletonWrap} from './MainPage';
import DescCharger from '../components/descCharger/DescCharger';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import {getMMDDHHMM, stringWithZum} from '../utils/common';
import useModal from "../hooks/useModal";

const ChargingPage = () => {
    const [searchParams] = useSearchParams();
    const urlCode = searchParams.get('code') || '';
    const idTag = searchParams.get('idTag') || '';
    const [isLoading, setIsLoading] = useState(true);
    const [chargeBox, setChargeBox] = useState<IChargeBox>();
    const [chargePoint, setChargePoint] = useState<IChargePoint>();
    const [chargeBasicInfo, setChargeBasicInfo] = useState<IChargeBasicInfo>({});
    const [transaction, setTransaction] = useState<ITransaction>();
    const [currentCharge, setCurrentCharge] = useState('0');
    const [currentAmount, setCurrentAmount] = useState('0');
    const modal = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        if (urlCode === '' || idTag === '') {
            navigate('/status?status=offline');
            return;
        }

        // get chargeBoxInformation
        adminApi
            .getChargeBasicInfo(urlCode)
            .then((res: any) => {
                if (res.data && res.data.resultCode === '0000' && res.data.result) {
                    setChargeBasicInfo(res.data.result);
                }
            })
            .catch((err: any) => {
                navigate('/status?status=offline');
                return;
            });
    }, []);

    useEffect(() => {
        if (chargeBasicInfo.chargePointId && chargeBasicInfo.chargeBoxId) {
            // Save data after first checking charging station and charger information
            plugitApi
                .getChargePoints(chargeBasicInfo.chargePointId)
                .then((res: any) => {
                    const data = res.data;
                    setChargePoint(data);
                    plugitApi
                        .getChargeBoxes(chargeBasicInfo.chargeBoxId)
                        .then((res: any) => {
                            const data = res.data;

                            setChargeBox(data);
                            setIsLoading(false);
                        })
                        .catch((err: any) => {
                            navigate('/status?status=offline');
                        });
                })
                .catch((err: any) => {
                    navigate('/status?status=offline');
                });
        }
    }, [chargeBasicInfo]);

    useEffect(() => {
        if (!transaction) {
            return;
        }

        if (transaction?.value && transaction?.meterStart) {
            const value = Number(transaction.value) - Number(transaction.meterStart);

            if (!isNaN(value)) {
                // 소수점 2자리 숫자 까지만
                setCurrentCharge((value / 1000).toFixed(2));
            }

            if (transaction?.amount && transaction?.price) {
                const resultAmount = Math.floor(value / 1000) * Number(transaction.price) + (chargeBasicInfo?.basePrice || 0);

                if (!isNaN(resultAmount)) {
                    setCurrentAmount(resultAmount + '');
                }
            }
        }
    }, [transaction, chargeBasicInfo]);

    // current transaction get
    useEffect(() => {
        if (chargeBasicInfo.chargePointId && chargeBasicInfo.chargeBoxId) {
            adminApi
                .getTranscactionInfo(idTag, chargeBasicInfo.chargePointId || '', chargeBasicInfo.chargeBoxId || '')
                .then((res: any) => {
                    if (res.data && res.data.resultCode === '0000' && res.data.result) {
                        const result: ITransaction = res.data.result;

                        // 충전이 완료 된 경우 충전 페이지로
                        if (result?.eventType === 'TransactionStop' && result?.state === 'finished') {
                            navigate('/finished?code=' + urlCode + '&idTag=' + idTag);
                        }
                        setTransaction(result);
                    }
                })
                .catch((err: any) => {
                    navigate('/status?status=offline');
                });
        }
    }, [chargeBasicInfo]);


    const handleStopCharging = () => {
        modal.openConfirm({
            key: 'StopCharging',
            title: 'Stop charging',
            message: 'Would you like to stop charging?',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            onRequestConfirm: () => {

                const data = {
                    chargeBoxId: chargeBasicInfo?.chargeBoxId,
                }
                plugitApi
                    .remoteStopTransaction(data)
                    .then((res: any) => {
                        if (res.data && res.data.status === 'Accepted') {
                            navigate('/main?code=' + urlCode);
                        } else {
                            alert('fail to stop charging');
                        }
                    }).catch((err: any) => {
                    alert('fail to stop charging');
                });
            },
            onRequestCancel: () => {
            },
        });
    };
    const handleShareClick = () => {
        if (!navigator.share) {
            alert('Sharing is only supported on mobile');
            return;
        }
        navigator.share({
            title: (chargePoint?.name || '') + ' station',
            text: 'pt-futura charging',
            url: 'https://www.pt-futura.com/charging?code=' + urlCode + '&idTag=' + idTag,
        });
    };

    return (
        <div className="container">
            <div className="inner">
                <TopBar text={isLoading ? '' : 'Charging activated'}/>
                {isLoading ? (
                    <Skeleton height={70} wrapper={SkeletonWrap} count={5}/>
                ) : (
                    <>
                        <div className="charger-wrap">
                            <div className="station">
                                <div className="sub-text">
                                    <img src="/assets/img/loc.png" alt="~" width={20} height={20}/>
                                    <div>Station</div>
                                </div>
                                <div className="main-text">{chargePoint?.name}</div>
                            </div>
                            <div className="station-info">
                                <StationInfo subText="Plug ID" mainText={chargeBox?.uniqueId}/>
                                <StationInfo
                                    subText="Plug type"
                                    mainText={chargeBox?.socketType}
                                    imgSrc={
                                        chargeBox?.socketType?.charAt(0) === 'T' ? '/assets/img/plugType1.png' : '/assets/img/plugType2.png'
                                    }
                                />
                                <StationInfo subText="Speed" mainText={(chargeBox?.power || 0) / 1000 + 'kw'}/>
                            </div>
                        </div>
                        <DescCharger
                            subText="Your charging is succesfully started"
                            descText={[
                                {
                                    title: 'Start time',
                                    data: getMMDDHHMM(transaction?.timeStart),
                                },
                                {
                                    title: 'Charge(kWh)',
                                    data: currentCharge,
                                },
                                {
                                    title: 'Payment',
                                    data: 'Rp' + stringWithZum(currentAmount),
                                },
                            ]}
                            stopCharging={true}
                            onStopCharging={handleStopCharging}
                        />
                        <div className="charger-action-wrap">
                            {chargeBasicInfo?.linkUrl && chargeBasicInfo?.btnNm && (
                                <>
                                    <div className="sub-text">Please visit our partner's channel</div>
                                    <a className="button-wrap" href={chargeBasicInfo?.linkUrl}>
                                        {chargeBasicInfo?.btnNm}
                                    </a>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className="footer">
                <div className="double">
                    <div className="item">
                        <button className="button-wrap" onClick={e => window.location.reload()}>
                            <RestartAltIcon fontSize="small"/>
                            <span>Refresh Page</span>
                        </button>
                    </div>
                    <div className="item">
                        <button className="button-wrap" onClick={handleShareClick}>
                            <SendToMobileIcon fontSize="small"/>
                            <span>Send to mobile</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChargingPage;
