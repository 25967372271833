import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useModal from '../../../../hooks/useModal';
import { adminApi } from '../../../../apis/admin';
import { Box, Button, Grid, Pagination, TextField } from '@mui/material';
import ChargeBoxTable from './ChargeBoxTable';
import { generateToken } from '../../../../utils/common';
import ChargeBoxModifiedPopup from '../../../../components/popup/ChargeBoxModifiedPopup';
import ChargeBoxDetailPopup from '../../../../components/popup/ChargeBoxDetailPopup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .charge-box-edit {
    display: flex;
    justify-content: center;

    .box {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ml {
      margin-left: 5px;
    }
  }
`;

interface IManageChargeBoxProps {
  chargePointId: string;
}

const ManageChargeBox = ({ chargePointId = '' }: IManageChargeBoxProps) => {
  const [page, setPage] = useState(1); // 처음 페이지는 1이다.
  const [tableData, setTableData] = useState([]); // 테이블 데이터
  const pageSize = 5; // 한 페이지에 보여줄 데이터의 개수
  const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
  const [chargeBoxId, setChargeBoxId] = useState(''); // 수정할 충전기의 id
  const modal = useModal();

  useEffect(() => {
    findChargeBox();
  }, [page, pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChargeBoxId(e.target.value);
  };

  const handleSearchClick = () => {
    findChargeBox();
  };

  const handleAddClick = () => {
    const data = {
      chargePointId: chargePointId,
      chargeBoxId: '',
      urlCode: generateToken(6),
      price: 0,
      basePrice: 0,
    };

    modal.openAlert({
      key: 'CHARGE_BOX_MODIFY',
      title: 'Please enter charging box information.',
      component: () => <ChargeBoxModifiedPopup data={data} type="create" />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('CHARGE_BOX_MODIFY');
        findChargeBox();
      },
    });
  };

  const handleUpdateClick = (data: any) => {
    modal.openAlert({
      key: 'CHARGE_BOX_MODIFY',
      title: 'Please enter charging box information.',
      component: () => <ChargeBoxModifiedPopup data={data} type="update" />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('CHARGE_BOX_MODIFY');
        findChargeBox();
      },
    });
  };

  const handleDeleteClick = (data: any) => {
    modal.openConfirm({
      key: 'CHARGE_BOX_DELETE',
      title: 'Delete charging box',
      message: 'Would you like to delete charging box?',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      onRequestConfirm: () => {
        adminApi
          .deleteChargeBox(data.chargePointId, data.chargeBoxId)
          .then((res: any) => {
            if (res.data && res.data.resultCode === '0000') {
              findChargeBox();
            }
          })
          .catch((err: any) => {
            window.alert('fail to delete charge point');
          });
      },
      onRequestCancel: () => {},
      onAfterClose: () => {
        modal.closeModal('CHARGE_BOX_DELETE');
      },
    });
  };

  const handleDetailClick = (data: any) => {
    modal.openAlert({
      key: 'DETAIL_CHARGE_BOX',
      title: 'Detail charge box information.',
      component: () => <ChargeBoxDetailPopup data={data} />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('DETAIL_CHARGE_BOX');
      },
    });
  };

  const findChargeBox = () => {
    adminApi
      .findChargeBox(page.toString(), pageSize.toString(), chargePointId, chargeBoxId)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setTableData(res.data.result.chargeBoxList);
          const total = res.data.result.total;
          const totalPage = Math.ceil(total / pageSize);
          setTotalPage(totalPage);
        }
      })
      .catch((err: any) => {
        window.alert('fail to find charge box');
      });
  };

  return (
    <Container>
      <Box mt={3} mb={3}>
        <h3>ChargePointId : {chargePointId}</h3>
      </Box>
      <Grid container className="charge-box-edit" spacing={2}>
        <Grid item xs={12} sm={6} className="box">
          <Button variant="contained" fullWidth onClick={handleAddClick}>
            Add ChargeBox
          </Button>
        </Grid>
      </Grid>
      <Box>
        <Box mt={2} className="row">
          <TextField size="small" label="ChargeBox ID" value={chargeBoxId} onChange={handleSearchChange} />
          <Button variant="outlined" className="ml" onClick={handleSearchClick}>
            search
          </Button>
        </Box>
        <Box mt={2}>
          <ChargeBoxTable
            data={tableData}
            onEditClick={handleUpdateClick}
            onDeleteClick={handleDeleteClick}
            onDetailClick={handleDetailClick}
          />
        </Box>
        <Box mt={2} className="charge-box-edit">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            boundaryCount={2}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ManageChargeBox;
