export const currentHHMM = () => {
  const date = new Date();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
};

export const currentTime = () => {
  const date = new Date();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour < 10 ? `0${hour}` : hour}${minute < 10 ? `0${minute}` : minute}`;
};

export const generateToken = (length: number) => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let result = '';
  for (let i = 0; i < length; i++) {
    const b = Math.floor(Math.random() * a.length);
    const c = a.charAt(b);
    result += c;
  }
  return result;
};

export const generateIdTag = (length: number) => {
  const a = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let result = 'SB';
  for (let i = 0; i < length; i++) {
    const b = Math.floor(Math.random() * a.length);
    const c = a.charAt(b);
    result += c;
  }
  return result;
};

// new Date() 를 YYYYMMDD 형식으로 반환
export const getYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}${month < 10 ? `0${month}` : month}${day < 10 ? `0${day}` : day}`;
};

// 3자리마다 점(.)를 찍어서 반환하는 함수
export const stringWithZum = (value: string) => {
  if (value === undefined || value === null || value === '' || value === 'null') {
    return '0';
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

// 3자리마다 점(.)를 찍어서 반환하는 함수
export const numberWithZum = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

// 3자리마다 콤마(,)를 찍어서 반환하는 함수
export const stringWithComma = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 3자리마다 콤마(,)를 찍어서 반환하는 함수
export const numberWithComma = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// "2023-12-08T20:30:15.000Z" 를 MM/DD HH:MM 형식으로 변환
export const getMMDDHHMM = (date: string | undefined | null) => {
  if (date === undefined || date === null || date === '' || date === 'null') {
    return '00:00';
  }

  const localDate = convertUtcToLocal(date);

  const month = ('0' + (localDate.getMonth() + 1)).slice(-2);
  const day = ('0' + localDate.getDate()).slice(-2);
  const hour = ('0' + localDate.getHours()).slice(-2);
  const minute = ('0' + localDate.getMinutes()).slice(-2);

  return `${month}/${day} ${hour}:${minute}`;
};

export const convertUtcToLocal = (utcTime: string) => {
  return new Date(utcTime);
};

export const convertUtcToLocalFromCreatedDt = (utcTime: string) => {
  // 2023-12-19 09:50:44 -> 2023-12-19T09:50:44Z
  const utcTimeWithZ = utcTime.replace(' ', 'T') + 'Z';
  return new Date(utcTimeWithZ);
};

// delayed API call
export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
