import { adminAxios } from '../../utils/axiosInstance';

const realApi = {
  login: (userId: string, password: string) => {
    return adminAxios.post('/api/user/login', { userId, password });
  },
  findChargePoint: (pageNo: string, pageSize: string, search: string) => {
    return adminAxios.get('/api/chargePoint/find', { params: { pageNo, pageSize, search } });
  },
  createChargePoint: (data: any) => {
    return adminAxios.post('/api/chargePoint/create', data);
  },
  updateChargePoint: (data: any) => {
    return adminAxios.post('/api/chargePoint/update', data);
  },
  deleteChargePoint: (chargePointId: string) => {
    return adminAxios.post('/api/chargePoint/delete', null, {
      params: { chargePointId: chargePointId },
    });
  },
  findUser: (pageNo: string, pageSize: string, search: string) => {
    return adminAxios.get('/api/user/find', { params: { pageNo, pageSize, search } });
  },
  createUser: (data: any) => {
    return adminAxios.post('/api/user/create', data);
  },
  updateUser: (data: any) => {
    return adminAxios.post('/api/user/update', data);
  },
  deleteUser: (userId: string) => {
    return adminAxios.post('/api/user/delete', null, {
      params: { userId },
    });
  },
  findTransaction: (pageNo: string, pageSize: string, search: string, startDate: string, endDate: string) => {
    return adminAxios.get('/api/transaction/find', { params: { pageNo, pageSize, search, startDate, endDate } });
  },
  findChargeBox: (pageNo: string, pageSize: string, chargePointId: string, chargeBoxId: string) => {
    return adminAxios.get('/api/chargeBox/find', { params: { pageNo, pageSize, chargePointId, chargeBoxId } });
  },
  createChargeBox: (data: any) => {
    return adminAxios.post('/api/chargeBox/create', data);
  },
  updateChargeBox: (data: any) => {
    return adminAxios.post('/api/chargeBox/update', data);
  },
  deleteChargeBox: (chargePointId: string, chargeBoxId: string) => {
    return adminAxios.post('/api/chargeBox/delete', null, {
      params: { chargePointId, chargeBoxId },
    });
  },
  checkChargePointPassword: (chargePointId: string, password: string) => {
    return adminAxios.get('/api/chargePoint/check/password', { params: { chargePointId, password } });
  },
  getChargeBasicInfo: (urlCode: string) => {
    return adminAxios.get('/api/chargeBox/findByCode', { params: { urlCode } });
  },
  getPaymentInfo: (amount: string, code: string) => {
    return adminAxios.get('/api/payment/request', { params: { amount, code } });
  },
  getRefundInfo: (orderId: string, amount: string) => {
    return adminAxios.get('/api/payment/refund', { params: { orderId, amount } });
  },
  createAfterPayment: (data: any) => {
    return adminAxios.post('/api/transaction/createAfterPayment', data);
  },
  getTranscactionInfo: (idTag: string, chargePointId: string, chargeBoxId: string) => {
    return adminAxios.get('/api/transaction/findByIdTag', { params: { idTag, chargePointId, chargeBoxId } });
  },
  getAutelInfo: () => {
    return adminAxios.get('/api/autel/info/find');
  },
  updateAutelInfo: (data: any) => {
    return adminAxios.post('/api/autel/info/update', data);
  },
  findAutelRowData: (pageNo: string, pageSize: string, search: string) => {
    return adminAxios.get('/api/autel/row/find', { params: { pageNo, pageSize, search } });
  },
  checkRfid: () => {
    return adminAxios.get('/api/user/test');
  }
};

export default realApi;
