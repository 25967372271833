import React from 'react';

import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import Alert from '../alert/Alert';
import DefaultModal from '../defaultModal/DefaultModal';
import { MODAL_TYPE } from '../../../constants/modal';
import { modalState } from '../../../stores/modal';

import type * as Modal from '../../../types/modal';
import Confirm from '../confirm/Confirm';

const ModalRoot = () => {
  const modals = useRecoilValue(modalState);

  type TModalList = Modal.IModalProps[];

  const modalList: TModalList = [];
  const alertList: TModalList = [];
  const confirmList: TModalList = [];

  modals.map(value => {
    switch (value.type) {
      case MODAL_TYPE.ALERT:
        alertList.push(value);
        break;
      case MODAL_TYPE.CONFIRM:
        confirmList.push(value);
        break;
      default:
        modalList.push(value);
        break;
    }
  });

  return (
    <div>
      <div>
        {modalList.map(props => (
          <DefaultModal key={props.key} {...props}></DefaultModal>
        ))}
      </div>
      <div>
        {alertList.map(props => (
          <Alert key={props.key} {...props}></Alert>
        ))}
      </div>
      <div>
        {confirmList.map(props => (
          <Confirm key={props.key} {...props}></Confirm>
        ))}
      </div>
    </div>
  );
};

ModalRoot.propTypes = {
  key: PropTypes.string,
};
export default ModalRoot;
