import { plugitAxios } from '../../utils/axiosInstance';

const realApi = {
  getChargePoints: (chargePointId?: string) => {
    return plugitAxios.get('/api/charge-points/' + chargePointId);
  },
  getChargeBoxes: (chargeBoxId?: string) => {
    return plugitAxios.get('/api/charge-boxes/' + chargeBoxId);
  },
  remoteStartTransaction: (data: any) => {
    return plugitAxios.post('/api/remote-commands/remote-start-transaction', data);
  },
  remoteStopTransaction: (data: any) => {
    return plugitAxios.post('/api/remote-commands/remote-stop-transaction', data);
  }
};

export default realApi;
