import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  data: any;
  onEditClick: any;
}

const AutelInfoTable = ({ data, onEditClick }: IProps) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>devices</TableCell>
            <TableCell>page</TableCell>
            <TableCell>pageSize</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={data.id}>
            <TableCell>
              <div className="short">{data.devices}</div>
            </TableCell>
            <TableCell>{data.page}</TableCell>
            <TableCell>{data.pageSize}</TableCell>
            <TableCell>
              <Button variant="contained" size="small" onClick={e => onEditClick(data)}>
                Edit
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutelInfoTable;
