import React from 'react';

import RecoilProvider from './recoil';
import ModalRoot from '../components/modals/modalRoot/ModalRoot';

interface IProps {
  children: React.ReactNode;
}

const Provider = ({ children }: IProps) => {
  return (
    <RecoilProvider>
      <ModalRoot />
      {children}
    </RecoilProvider>
  );
};

export default Provider;
