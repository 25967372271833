import { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const MidtransPage = () => {
  const [snap, setSnap] = useState(null);

  const handleShare = () => {
    if (!navigator.share) {
      alert('This browser does not support the share API');
      return;
    }
    navigator.share({
      title: 'share test',
      text: 'Naver',
      url: 'https://naver.com',
    });
  };

  useEffect(() => {
    // You can also change below url value to any script url you wish to load,
    // for example this is snap.js for Sandbox Env (Note: remove `.sandbox` from url if you want to use production version)
    const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';

    const scriptTag = document.createElement('script');
    scriptTag.src = midtransScriptUrl;
    scriptTag.type = 'text/javascript';
    scriptTag.onload = () => {
      if ('snap' in window) {
        const { snap } = window;
        // @ts-ignore
        setSnap(snap);
      }
    };

    scriptTag.dataset.clientKey = 'SB-Mid-client-iokuuy6qt4Qc0rdi';

    document.head.appendChild(scriptTag);

    return () => {
      document.head.removeChild(scriptTag);
    };
  }, []);

  const handleClick = () => {
    // @ts-ignore
    snap.pay('dbd1eb8c-9c26-4131-8f79-261c95ea69ec');
  };

  return (
    <div>
      <h1>Midtrans Page</h1>
      <button onClick={handleClick}> 결제창</button>
      <button onClick={handleShare}> 공유하기</button>
      <QRCodeCanvas value="www.naver.com/" />
    </div>
  );
};

export default MidtransPage;
