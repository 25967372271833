import TopBar from '../components/topBar/TopBar';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import OccupiedInfo from '../components/occupiedInfo/OccupiedInfo';

const StatusPage = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const [timeLimitDescText, setTimeLimitDescText] = useState('');

  const [topBarText, setTopBarText] = useState('Loading...');

  useEffect(() => {
    if (status === 'occupied') {
      setTopBarText('Charger occupied');
    } else if (status === 'invalid') {
      setTopBarText('Invalid command');
    } else if (status === 'offline') {
      setTopBarText('Charger offline');
    } else if (status === 'complete') {
      setTopBarText('Charger complete');
    }
  }, [status]);

  useEffect(() => {
    if (start && end) {
      setTimeLimitDescText(
        'Out of a business hour(' +
          start.substring(0, 2) +
          ':' +
          start.substring(2, 4) +
          ' ~ ' +
          end.substring(0, 2) +
          ':' +
          end.substring(2, 4) +
          ').\nplease come back again ',
      );
    }
  }, [start, end]);

  return (
    <div className="container">
      <div className="inner">
        <TopBar text={topBarText} />

        <div className="status-wrap">
          {status === 'occupied' && (
            <OccupiedInfo
              occupied={true}
              titleText={topBarText}
              subText="The current charging session is in a progress"
              descText="Please kindly wait until the current user finishes a charging session"
            />
          )}
          {status === 'offline' && !timeLimitDescText && (
            <OccupiedInfo
              occupied={false}
              titleText={topBarText}
              subText="Sorry for your inconvenience"
              descText="Please follow the instruction from the customer center"
            />
          )}
          {status === 'offline' && timeLimitDescText && (
            <OccupiedInfo
              occupied={false}
              titleText={topBarText}
              subText="Sorry for your inconvenience"
              descText={timeLimitDescText}
            />
          )}
          {status === 'invalid' && (
            <OccupiedInfo
              occupied={false}
              titleText={topBarText}
              subText="Sorry for your inconvenience"
              subTextWeek="There is a system error"
              descText="Please contact a customer center"
            />
          )}
          {status === 'complete' && (
            <OccupiedInfo
              occupied={true}
              titleText={topBarText}
              subText="The current charging session is finished"
              descText="If it is not your choice to finish a charging, please check a setting of EV. There may be a maximum charging limit by EV"
            />
          )}
          <div className="action-wrap">
            {!start && !end && (
              <a className="btn-wrap" href="https://wa.me/628818878878">
                Customer center
                <div className="img-wrap">
                  <img src="/assets/img/phone.png" alt="~" />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusPage;
