import LayoutContainer from '../../../containers/layout/LayoutContainer';
import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useModal from '../../../hooks/useModal';
import { adminApi } from '../../../apis/admin';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../../../utils/cookies';

const LoginPage = () => {
  const modal = useModal();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // @ts-ignore
    const userId = data.get('id').toString();
    // @ts-ignore
    const password = data.get('password').toString();

    if (!userId || !password) {
      modal.openAlert({
        key: 'LOGIN_FAIL',
        title: 'Login fail',
        message: 'Please enter your id and password.',
        confirmButtonText: 'Close',
        onAfterClose: () => {
          modal.closeModal('LOGIN_FAIL');
        },
      });
      return;
    }

    adminApi
      .login(userId, password)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          const resUserId = res.data.result?.userId;
          const resAccessToken = res.data.result?.token;
          setCookie('adminToken', resAccessToken);
          setCookie('adminId', resUserId);
          navigate('/admin');
        }
      })
      .catch((err: any) => {
        modal.openAlert({
          key: 'LOGIN_FAIL',
          title: 'Login fail',
          message: 'Please check your id and password.',
          confirmButtonText: 'Close',
          onAfterClose: () => {
            modal.closeModal('LOGIN_FAIL');
          },
        });
      });
  };

  return (
    <LayoutContainer>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in Admin
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="id" label="id" name="id" autoFocus />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </LayoutContainer>
  );
};

export default LoginPage;
