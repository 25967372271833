import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  padding: 16px 16px;
  @media (min-width: 375px) {
    padding: 18px 16px;
  }
  @media (min-width: 450px) {
    padding: 20px 20px;
  }
  @media (min-width: 900px) {
    padding: 25px 25px;
  }
  position: relative;
  width: 100%;
  text-align: center;

  .sub-container {
    position: absolute;
    background: rgba(2, 13, 24, 0.8);
    backdrop-filter: blur(7px);
    width: calc(100% - 64px);
    left: 32px;
    bottom: 32px;
    padding: 18px 12px;
  }

  .sub-text {
    text-align: left;

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    @media (min-width: 375px) {
      font-size: 25px;
      line-height: 31px;
    }
    @media (min-width: 450px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (min-width: 900px) {
      font-size: 30px;
      line-height: 36px;
    }
    color: #ffffff;

    .blue {
      font-weight: 700;
      color: #00aad2;
    }
  }

  .line {
    border: 1px solid #cccccc;
    margin-top: 6px;
  }

  .desc-wrap {
    display: flex;
    margin-top: 6px;
  }

  .desc-text1 {
    text-align: left;
    flex: 1;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 29px;
    }

    color: #cccccc;
  }

  .desc-text2 {
    text-align: left;
    flex: 1;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 29px;
    }
    color: #ffffff;
  }

  .image-tag {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .button-wrap {
    margin-top: 10px;
    width: 100%;
    background: #FFFFFF;
    padding: 5px 0px;
    cursor: pointer;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    @media (min-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 900px) {
      font-size: 20px;
      line-height: 29px;
    }
    color: #000000;
  }
`;

interface DescChargerProps {
    subText?: string;
    subBlueText?: string;
    descText?: any[];
    stopCharging?: boolean;
    onStopCharging?: any;
    chargeBoxId?: string;
}

// charger status screen
const DescCharger = ({subText, subBlueText, descText, stopCharging, onStopCharging}: DescChargerProps) => {


    return (
        <Container>
            <img src="/assets/img/descCharger.png" alt="~" className="image-tag"/>
            <div className="sub-container">
                <div className="sub-text">
                    {subText}
                    {subBlueText && <div className="blue">00 minutes</div>}
                </div>
                {descText && <div className="line"></div>}

                {descText?.map((text, index) => (
                    <div className="desc-wrap" key={index}>
                        <div className="desc-text1">{text.title}</div>
                        <div className="desc-text2">{text.data}</div>
                    </div>
                ))}
                {stopCharging && onStopCharging &&
                    <>
                        <div className="line"></div>
                        <button className="button-wrap" onClick={onStopCharging}>
                            Stop charging
                        </button>
                    </>
                }

            </div>
        </Container>
    );
};

export default DescCharger;
