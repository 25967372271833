import styled from 'styled-components';
import { useState } from 'react';
import useModal from '../../hooks/useModal';
import { adminApi } from '../../apis/admin';
import { Box, Button, TextField } from '@mui/material';

const Container = styled.div``;

interface IProps {
  data: any;
  type: string;
}

const AutelInfoModifiedPopup = ({ data, type }: IProps) => {
  const [id, setId] = useState(data?.id || '');
  const [devices, setDevices] = useState(data?.devices || '');
  const [page, setPage] = useState(data?.page || '');
  const [pageSize, setPageSize] = useState(data.pageSize || '');

  const modal = useModal();

  const handleUpdateClick = () => {
    if (devices === '' || page === '' || pageSize === '') {
      window.alert('Please input all data');
      return;
    }

    const data = {
      id: id,
      devices: devices,
      page: page,
      pageSize: pageSize,
    };
    adminApi
      .updateAutelInfo(data)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000') {
          modal.closeModal('AUTELINFO_MODIFY');
        }
      })
      .catch((err: any) => {
        window.alert('fail to update autel info');
      });
  };

  return (
    <Container>
      <Box mt={2}>
        <TextField
          size="small"
          label="Devices*"
          multiline
          rows={3}
          value={devices}
          onChange={e => {
            setDevices(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="Page*"
          type="number"
          value={page}
          onChange={e => {
            setPage(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          size="small"
          label="PageSize*"
          type="number"
          value={pageSize}
          onChange={e => {
            setPageSize(e.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        {type === 'update' && (
          <Button variant="contained" fullWidth onClick={handleUpdateClick}>
            Update
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default AutelInfoModifiedPopup;
