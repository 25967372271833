import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { IChargeBasicInfo, IChargeBox, IChargePoint, ITransaction } from '../types/common';
import { adminApi } from '../apis/admin';
import { plugitApi } from '../apis/plugit';
import TopBar from '../components/topBar/TopBar';
import OccupiedInfo from '../components/occupiedInfo/OccupiedInfo';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrap } from './MainPage';
import { getMMDDHHMM, stringWithZum } from '../utils/common';

const FinisedPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const urlCode = searchParams.get('code') || '';
  const idTag = searchParams.get('idTag') || '';
  const [isLoading, setIsLoading] = useState(true);
  const [chargeBox, setChargeBox] = useState<IChargeBox>();
  const [chargePoint, setChargePoint] = useState<IChargePoint>();
  const [chargeBasicInfo, setChargeBasicInfo] = useState<IChargeBasicInfo>({});
  const [transaction, setTransaction] = useState<ITransaction>();
  const [currentCharge, setCurrentCharge] = useState('0');

  useEffect(() => {
    if (urlCode === '' || idTag === '') {
      navigate('/status?status=offline');
      return;
    }

    // get chargeBoxInformation
    adminApi
      .getChargeBasicInfo(urlCode)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setChargeBasicInfo(res.data.result);
        }
      })
      .catch((err: any) => {
        navigate('/status?status=offline');
        return;
      });
  }, []);

  useEffect(() => {
    if (chargeBasicInfo.chargePointId && chargeBasicInfo.chargeBoxId) {
      // Save data after first checking charging station and charger information
      plugitApi
        .getChargePoints(chargeBasicInfo.chargePointId)
        .then((res: any) => {
          const data = res.data;
          setChargePoint(data);
          plugitApi
            .getChargeBoxes(chargeBasicInfo.chargeBoxId)
            .then((res: any) => {
              const data = res.data;

              setChargeBox(data);
              setIsLoading(false);
            })
            .catch((err: any) => {
              navigate('/status?status=offline');
            });
        })
        .catch((err: any) => {
          navigate('/status?status=offline');
        });
    }
  }, [chargeBasicInfo]);

  // current transaction get
  useEffect(() => {
    if (chargeBasicInfo.chargePointId && chargeBasicInfo.chargeBoxId) {
      adminApi
        .getTranscactionInfo(idTag, chargeBasicInfo.chargePointId || '', chargeBasicInfo.chargeBoxId || '')
        .then((res: any) => {
          if (res.data && res.data.resultCode === '0000' && res.data.result) {
            const result: ITransaction = res.data.result;

            setTransaction(result);
          }
        })
        .catch((err: any) => {
          navigate('/status?status=offline');
        });
    }
  }, [chargeBasicInfo]);

  const handleShareClick = () => {
    if (!navigator.share) {
      alert('Sharing is only supported on mobile');
      return;
    }
    navigator.share({
      title: (chargePoint?.name || '') + ' station',
      text: 'pt-futura charging complete',
      url: 'https://www.pt-futura.com/finished?code=' + urlCode + '&idTag=' + idTag,
    });
  };

  useEffect(() => {
    if (!transaction) {
      return;
    }

    if (transaction?.energy) {
      const value = Number(transaction.energy);
      if (!isNaN(value)) {
        setCurrentCharge((value / 1000).toFixed(2));
      }
    }
  }, [transaction]);

  return (
    <div className="container">
      <div className="inner">
        <TopBar text="Charging complete" />
        {isLoading ? (
          <Skeleton height={70} wrapper={SkeletonWrap} count={5} />
        ) : (
          <>
            <div className="status-wrap">
              <OccupiedInfo
                occupied={true}
                titleText="Charging complete"
                subText="The current charging session is finished"
                descText="If it is not your choice to finish a charging, please check a setting of EV. There may be a maximum charging limit by EV"
                transactionText={[
                  {
                    title: 'Start time',
                    data: getMMDDHHMM(transaction?.timeStart),
                  },
                  {
                    title: 'End time',
                    data: getMMDDHHMM(transaction?.timeStop),
                  },
                  {
                    title: 'Charge(kWh)',
                    data: currentCharge,
                  },
                  {
                    title: 'Payment',
                    data: 'Rp' + stringWithZum(transaction?.amount || '0'),
                  },
                ]}
              />
              <div className="action-wrap">
                <a className="btn-wrap" href="https://wa.me/628818878878">
                  Customer center
                  <div className="img-wrap">
                    <img src="/assets/img/phone.png" alt="~" />
                  </div>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="footer">
        <div className="one">
          <button className="button-wrap" onClick={handleShareClick}>
            <SendToMobileIcon fontSize="small" />
            <span>Send to mobile</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinisedPage;
