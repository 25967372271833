import React, { useEffect } from 'react';

import classnames from 'classnames';

import type * as Modal from '../../../types/modal';

const Confirm = ({
  onAfterOpen,
  onAfterClose,
  isOpen = false,
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  component,
  onRequestClose,
  onRequestCancel,
  onRequestConfirm,
}: Modal.IModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    onAfterOpen && onAfterOpen();
    return () => onAfterClose && onAfterClose();
  }, []);

  const handleCancelClick = () => {
    onRequestCancel && onRequestCancel();
    onRequestClose && onRequestClose();
  };
  const handleConfirmClick = () => {
    onRequestConfirm && onRequestConfirm();
    onRequestClose && onRequestClose();
  };

  return (
    <div className={classnames('modal-wrap', isOpen ? 'fade-in' : 'fade-out')}>
      <div className="modal">
        <div className="title">{title}</div>
        <div className="content">
          {message && message}
          {component && component()}
        </div>
        <div className="button-double-wrap">
          <div className="cancel-box" onClick={handleCancelClick}>
            {cancelButtonText}
          </div>
          <div className="cancel-box" onClick={handleConfirmClick}>
            {confirmButtonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
