import { useEffect, useState } from 'react';
import useModal from '../../../../hooks/useModal';
import { adminApi } from '../../../../apis/admin';
import styled from 'styled-components';
import { Box, Button, Grid, Pagination, TextField } from '@mui/material';
import AutelInfoTable from './AutelInfoTable';
import AutelInfoModifiedPopup from '../../../../components/popup/AutelInfoModifiedPopup';
import AutelRowTable from './AutelRowTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .autel-edit {
    display: flex;
    justify-content: center;

    .box {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ml {
      margin-left: 5px;
    }
  }
`;
const ManageAutel = () => {
  const [page, setPage] = useState(1); // 처음 페이지는 1이다.
  const [search, setSearch] = useState(''); // 검색어
  const [autelInfoTableData, setAutelInfoTableData] = useState({});
  const [autelRowTableData, setAutelRowTableData] = useState([]); // 테이블 데이터
  const pageSize = 10; // 한 페이지에 보여줄 데이터의 개수
  const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
  const modal = useModal();

  useEffect(() => {
    findAutelInfo();
    findAutelRowData();
  }, [page, pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    findAutelRowData();
  };

  const findAutelInfo = () => {
    adminApi
      .getAutelInfo()
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setAutelInfoTableData(res.data.result);
        }
      })
      .catch((err: any) => {
        window.alert('fail to find autelInfo');
      });
  };

  const findAutelRowData = () => {
    adminApi
      .findAutelRowData(page.toString(), pageSize.toString(), search)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setAutelRowTableData(res.data.result.autelList);
          const total = res.data.result.total;
          const totalPage = Math.ceil(total / pageSize);
          setTotalPage(totalPage);
        }
      })
      .catch((err: any) => {
        window.alert('fail to find autel Row Data');
      });
  };

  const handleAutelInfoEditClick = (data: any) => {
    modal.openAlert({
      key: 'AUTELINFO_MODIFY',
      title: 'Please enter autel basic information.',
      component: () => <AutelInfoModifiedPopup data={data} type="update" />,
      confirmButtonText: 'Close',
      onAfterClose: () => {
        modal.closeModal('AUTELINFO_MODIFY');
        findAutelInfo();
      },
    });
  };

  return (
    <Container>
      <Box mt={3} mb={3}>
        <h3>Manage Autel</h3>
      </Box>
      <Grid container className="autel-edit" spacing={2}>
        <Box mt={2}>
          <AutelInfoTable data={autelInfoTableData} onEditClick={handleAutelInfoEditClick} />
        </Box>
      </Grid>
      <Box>
        <Box mt={2} className="row">
          <TextField size="small" label="sn(device)" value={search} onChange={handleSearchChange} />
          <Button variant="outlined" className="ml" onClick={handleSearchClick}>
            search
          </Button>
        </Box>
        <Box mt={2}>
          <AutelRowTable data={autelRowTableData} />
        </Box>
        <Box mt={2} className="autel-edit">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            boundaryCount={2}
          />
        </Box>
      </Box>
    </Container>
  );
};
export default ManageAutel;
