import { Box, Button, Grid, Pagination, TextField } from '@mui/material';
import styled from 'styled-components';
import ChargePointTable from './ChargePointTable';
import { useEffect, useState } from 'react';
import { adminApi } from '../../../../apis/admin';
import SelectChargePointTable from './SelectChargePointTable';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .charge-point-select {
    display: flex;
    justify-content: center;

    .box {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;

    .ml {
      margin-left: 5px;
    }
  }
`;

const SelectChargePoint = () => {
  const [page, setPage] = useState(1); // 처음 페이지는 1이다.
  const [search, setSearch] = useState(''); // 검색어
  const [tableData, setTableData] = useState([]); // 테이블 데이터
  const pageSize = 5; // 한 페이지에 보여줄 데이터의 개수
  const [totalPage, setTotalPage] = useState(0); // 총 페이지 수
  const navigate = useNavigate();

  useEffect(() => {
    findChargePoint();
  }, [page, pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    findChargePoint();
  };

  const findChargePoint = () => {
    adminApi
      .findChargePoint(page.toString(), pageSize.toString(), search)
      .then((res: any) => {
        if (res.data && res.data.resultCode === '0000' && res.data.result) {
          setTableData(res.data.result.chargePointList);
          const total = res.data.result.total;
          const totalPage = Math.ceil(total / pageSize);
          setTotalPage(totalPage);
        }
      })
      .catch((err: any) => {
        window.alert('fail to find charge point');
      });
  };

  const handleSelectChargePoint = (chargePointId: string) => {
    navigate(`/admin/dashboard?chargePointId=${chargePointId}`);
  };

  return (
    <Container>
      <Box mt={3} mb={3}>
        <h3>Select ChargePoint</h3>
      </Box>
      <Box>
        <Box mt={2} className="row">
          <TextField size="small" label="ChargePoint ID" value={search} onChange={handleSearchChange} />
          <Button variant="outlined" className="ml" onClick={handleSearchClick}>
            search
          </Button>
        </Box>
        <Box mt={2}>
          <SelectChargePointTable data={tableData} onSelectClick={handleSelectChargePoint} />
        </Box>
        <Box mt={2} className="charge-point-select">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            boundaryCount={2}
          />
        </Box>
      </Box>
    </Container>
  );
};
export default SelectChargePoint;
